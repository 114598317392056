<template>
  <!-- main  -->
  <section class="main-section">
    <div class="container">
      <!-- main / welcome -->
      <div class="main">
        <div class="row">
          <div class="col-lg-6">
            <div class="left">
              <div>
                <h2 data-aos="fade-in">{{ $t("Welcome") }}</h2>
              </div>
              <p data-aos="fade-in">
                {{ $t("WelcomeDesc") }}
              </p>
              <router-link
                to="/contact"
                :title="$t('Contact')"
                data-aos="fade-in"
                class="btn"
                >{{ $t("Contact") }} &#x2192;</router-link
              >
            </div>
            <div class="container scroll-btn text-center">
              <a href="#services" title="Down"
                ><img src="@/assets/arrow.png" alt="arrow"
              /></a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="right">
              <div class="col main-icons">
                <img
                  src="@/assets/icons/app-store.png"
                  alt="app-store"
                  :title="$t('VerySoon')"
                />
                <img
                  src="@/assets/icons/play-store.png"
                  alt="play-store"
                  :title="$t('VerySoon')"
                />
                <img
                  src="@/assets/icons/huawei-app-store.png"
                  alt="huawei-app-store"
                  :title="$t('VerySoon')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ### boxes ### -->
  <section>
    <div class="container inner-page mt-5">
      <div class="row boxes">
        <div class="col-lg-4" data-aos="fade-in" data-aos-delay="200">
          <div>
            <span>01</span>
            <h3>
              {{ $t("Boxes1Desc") }}
            </h3>
          </div>
        </div>
        <div class="col-lg-4" data-aos="fade-in" data-aos-delay="400">
          <div>
            <span>02</span>
            <h3>
              {{ $t("Boxes2Desc") }}
            </h3>
          </div>
        </div>
        <div class="col-lg-4" data-aos="fade-in" data-aos-delay="600">
          <div>
            <span>03</span>
            <h3>
              {{ $t("Boxes3Desc") }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ### services ### -->
  <section>
    <div class="container inner-page">
      <div class="row services mt-5 pt-5" id="services">
        <div class="col-lg-9">
          <h3 data-aos="fade-in">{{ $t("HowWeCanHelpYou") }}</h3>
          <h2 data-aos="fade-in">{{ $t("ServicesWeOffer") }}</h2>
        </div>
        <div class="col-lg-6">
          <!-- <p>
          Uzman ekibimiz, bulut bilişim ve veri analitiği gibi en son
          teknolojilere hakimdir. Uzmanlık seviyeleri, sürekli olarak
          teknolojik gelişmeleri takip ederek güncellenir ve yenilikleri
          uygulama konusunda yeteneklidirler.
        </p> -->
        </div>
      </div>
    </div>
  </section>
  <!-- home top section end !!! -->

  <!-- sticky wrapper -->
  <div class="mt-5 sw"></div>

  <section>
    <div class="container-fluid">
      <!-- ### -->
      <div class="container text-center my-5">
        <p data-aos="fade-in">
          {{ $t("ServicesDesc") }}
        </p>
      </div>
    </div>
  </section>

  <!-- manage lands -->
  <section class="lands pt-3">
    <div class="container-fluid">
      <div class="container text-center my-5">
        <div class="row">
          <div class="col-lg-6 left">
            <h2 data-aos="fade-in">{{ $t("PropertyManagement") }}</h2>
            <p data-aos="fade-in" data-aos-delay="100">
              {{ $t("PropertyManagement1Desc") }}
            </p>
            <p data-aos="fade-in" data-aos-delay="300">
              {{ $t("PropertyManagement2Desc") }}
            </p>
            <p data-aos="fade-in" data-aos-delay="500">
              {{ $t("PropertyManagement3Desc") }}
            </p>
          </div>
          <div class="col-lg-6 right">
            <figure data-aos="fade-in" data-aos-delay="700">
              <img
                class="img-thumbnail"
                src="@/assets/pages/lands.jpg"
                alt="lands"
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- technology expert -->
  <section class="technology pt-3">
    <div class="container-fluid">
      <div class="container text-center my-5">
        <div class="row">
          <div class="col-lg-12">
            <h2 data-aos="fade-in">{{ $t("TechnologicalExpertise") }}</h2>
            <p data-aos="fade-in" data-aos-delay="100">
              {{ $t("TechnologicalExpertise1Desc") }}
            </p>
            <p data-aos="fade-in" data-aos-delay="300">
              {{ $t("TechnologicalExpertise2Desc") }}
            </p>
            <p data-aos="fade-in" data-aos-delay="500">
              {{ $t("TechnologicalExpertise3Desc") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- home gallery -->
  <section>
    <div class="container">
      <div class="row home-gallery text-center">
        <div class="col-lg-4" data-aos="fade-in" data-aos-delay="200">
          <figure>
            <img
              class="img-thumbnail"
              src="@/assets/home-gallery/gallery-1.jpg"
              alt="gallery"
            />
          </figure>
        </div>
        <div class="col-lg-4" data-aos="fade-in" data-aos-delay="400">
          <figure>
            <img
              class="img-thumbnail"
              src="@/assets/home-gallery/gallery-2.jpg"
              alt="gallery"
            />
          </figure>
        </div>
        <div class="col-lg-4" data-aos="fade-in" data-aos-delay="600">
          <figure>
            <img
              class="img-thumbnail"
              src="@/assets/home-gallery/gallery-3.jpg"
              alt="gallery"
            />
          </figure>
        </div>
      </div>
    </div>
  </section>

  <!-- contact -->
  <section>
    <div class="container">
      <Contact />
    </div>
  </section>
</template>
<script>
import Contact from "@/components/Contact-Inner.vue";

export default {
  name: "Home-Page",
  components: { Contact },
};
</script>
